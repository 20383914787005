@import url('~normalize.css/normalize.css');
@import url('~@reach/tooltip/styles.css');

:-moz-focusring {
  outline: 0 !important;
}

ol, ul {
  list-style: none;
  margin-bottom: 0;
}

ul li::before {
  content: "\200B";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/** Remove recaptcha badge from site  */
.grecaptcha-badge {
  visibility: hidden;
}
